import store from '@/store';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '客户编号', prop: 'cust_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户全称', prop: 'cust_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户属性', prop: 'cust_attr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '所属国家', prop: 'cust_country', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '联系人', prop: 'cust_contact', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '手机号', prop: 'cust_phone', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '指定外销员', prop: 'cust_stff_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '外销员所属部门', prop: 'cust_dept_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  store.state.stff_name,
  store.state.create_time,
  store.state.status
];
export const syncCustTableProperties = [
  store.state.index,
  { label: '客户全称', prop: 'cust_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '指定外销员', prop: 'cust_stff_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '外销所属部门', prop: 'cust_dept_name', itemType: 'input', input: true, sortable: false, widthAuto: true }
];
