<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新</el-button>
        <el-button type="success" size="small" @click="showSyncCustDataDialog" icon="el-icon-refresh">同步</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'cust_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        :need-fixed-height="true"
        @getTableData="getCustsList"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
      <el-dialog title="同步客户信息" width="70%" top="0" :visible.sync="syncCustDialogVisible" @opened="getSyncCustData">
        <search-table
          v-if="syncCustDialogVisible"
          ref="syncCustTable"
          :tableRowKey="`autoInc`"
          :data="syncCustTableData"
          :totalPage="syncCustTotalPage"
          :columns="syncCustTableProperties"
          v-loading="syncLoadFlag"
          @row-dblclick="syncCustData"
          @getTableData="getSyncCustData"
          :need-fixed-height="true"
        />
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, post } from '@api/request';
import { custAPI } from '@api/modules/cust';
import pubPagination from '@/components/common/pubPagination';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { syncCustTableProperties, tableProperties } from '@/views/MessageManagement/CustManage/cust';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'CustList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      tableProperties: tableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      syncCustDialogVisible: false,
      syncCustTableData: [],
      syncCustTotalPage: 0,
      syncCustTableProperties: syncCustTableProperties,
      syncLoadFlag: true
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === '/cust_add' || from.path === '/cust_edit') {
        this.initData();
      }
    }
  },
  mounted() {
    if (this.$route.query.cust_id) {
      this.$refs.multiTable.searchForm.cust_id = Number(this.$route.query.cust_id);
    }
    this.getCustsList();
  },
  methods: {
    initData() {
      this.getCustsList();
    },
    showSyncCustDataDialog() {
      this.syncCustDialogVisible = true;
    },
    syncCustData(row) {
      this.syncCustDialogVisible = false;
      this.$router.push({
        name: 'CustAdd',
        query: { perm_id: this.$route.query.perm_id, autoInc: row.rich_erp_id },
        params: { syncCustData: row }
      });
    },
    async getSyncCustData() {
      await (this.syncLoadFlag = true);
      await custAPI.getRichErpCust(this.$refs.syncCustTable.searchForm).then(({ data }) => {
        this.syncCustTableData = data.list;
        this.syncCustTotalPage = data.total;
        this.syncLoadFlag = false;
      });
    },
    async getCustsList() {
      await (this.loadFlag = true);
      await getNoCatch(custAPI.getCusts, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getCustsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/cust_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.cust_id
          })
        )
      });
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      const personal = this.$cookies.get('userInfo').personal;
      this.jump(`/cust_add`, { perm_id: permId, personal: personal });
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //客户信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.cust_id);
          });
          post(custAPI.deleteCustByIds, { cust_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
